<template>
  <div class="padding-top">
    <div class="page-segment padding-x-zero">
      <div class="page-segment-content">
        <SeliaNotifications />
        <ShortcutsSection
          @action="handlerShortcutType"
          :lang="$store.getters.language.lang"
          :store="$store"
          origin="SPECIALIST"
        />
      </div>
    </div>

    <div class="page-segment padding-top-zero">
      <div class="page-segment-content">
        <NextAppointment />
      </div>
    </div>

    <div class="page-segment">
      <div class="page-segment-content">
        <PatientsList />
      </div>
    </div>
  </div>
</template>

<script>
import NextAppointment from '@/views/home/components/NextAppointment'
import PatientsList from '@/views/home/components/PatientsList'
import SeliaNotifications from '@/views/home/components/SeliaNotifications'
import { Browser } from '@capacitor/browser'

import { ShortcutsSection, Settings } from '@seliaco/red-panda'

export default {
  name: 'Home',
  components: {
    SeliaNotifications,
    PatientsList,
    NextAppointment,
    ShortcutsSection
  },
  methods: {
    handlerShortcutType (key) {
      switch (key.type) {
        case 'EVENT':
          this.handlerShortcutAction(key.action)
          break
        case 'INTERNAL_REDIRECT':
          this.$router.push({
            path: key.action
          })
          break
        case 'EXTERNAL_REDIRECT':
          Browser.open({ url: key.action })
          break
      }
    },
    async handlerShortcutAction (key) {
      switch (key) {
        case 'SUGGESTIONS_TYPEFORM': {
          const url = await Settings.get('SELIA_SPECIALIST_SUGGESTION_FORM_URL')
            .then((response) => response.parse_value.replaceAll('{SPECIALIST_ID}', this.$store.getters['auth/specialist'].id))
          await Browser.open({ url })
          break
        }
      }
    }
  }
}
</script>
