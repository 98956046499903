<template>
  <div class="patient-list">
    <Heading
      :text="$translations.patients.title"
      :action="viewMore"
      class="margin-x"
    />

    <div class="carrousel-container margin-top padding-bottom" v-if="loading">
      <Skeleton
        v-for="(v, i) in 4"
        :key="i"
        width="120px"
        height="124px"
        borderRadius="16px"
      />
    </div>

    <div class="carrousel-container margin-top padding-bottom" v-else-if="patients && patients.length > 0">
      <PatientCard v-for="(patient, index) in patients" :key="index" :patient="patient" />
    </div>

    <DataZeroSimple :text="$translations['data-zero'].patients" v-else />
  </div>
</template>

<script>
import { SpecialistsPatients, SpecialistsServicesTypes, Heading, Skeleton } from '@seliaco/red-panda'
import PatientCard from '@/components/cards/PatientCard'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'

export default {
  name: 'PatientsList',
  components: { DataZeroSimple, PatientCard, Skeleton, Heading },
  data () {
    return {
      loading: true,
      patients: null,
      viewMore: {
        text: this.$translations.general['view-all'],
        callback: () => this.$router.push({ name: 'Patients' })
      }
    }
  },
  methods: {
    getPatients () {
      this.loading = true

      SpecialistsPatients.read({
        columns: `
          patient (
            id,
            first_name,
            last_name,
            profile_picture
          ),
          services
        `,
        eq: {
          specialist: this.$store.getters['auth/specialist'].id
        },
        perPage: 8,
        page: 1
      })
        .then(async (response) => {
          const patients = response.data || []

          this.patients = await Promise.all((patients).map(async o => {
            const services = await this.getService(o.services[0])

            return {
              id: o.patient.id,
              name: `${o.patient.first_name} ${o.patient.last_name}`,
              avatar: o.patient.profile_picture,
              service: services.data[0].name_es
            }
          }))

          this.loading = false
        })
    },
    async getService (id) {
      return SpecialistsServicesTypes.read({
        columns: `
          services_types(
            name_es
          )
        `,
        eq: {
          id
        },
        deleted: 'ignore'
      })
    }
  },
  mounted () {
    if (this.$store.getters['auth/specialist']) {
      this.getPatients()
    } else {
      this.$store.watch(
        () => this.$store.getters['auth/specialist'],
        () => {
          this.getPatients()
        }
      )
    }
  }
}
</script>

<style lang="sass" scoped>
.patient-list
  overflow: hidden
</style>
