<template>
  <div class="padding-all">
    <div class="header">
      <div class="heading-small" v-text="$translations.appointment.next" />
      <div
        class="text-purple content-title cursor-pointer"
        @click="$router.push({ name: 'Agenda' })"
        v-text="$translations.general['view-all']"
      />
    </div>

    <!-- loader -->
    <div v-if="loading">
      <Skeleton class="margin-y" width="160px" height="21px" borderRadius="8px" />
      <Skeleton width="100%" height="107px" borderRadius="16px" />
    </div>

    <!-- next appointment date -->
    <template v-else-if="appointment">
      <div class="content-title margin-top-lg" v-text="nextAppointmentDate" />

      <!-- appointment -->
      <SpecialistsAppointmentCard
        class="margin-top"
        v-selectable-container="{ callback: goToDetail, elementLevel: 2 }"
        :show-arrow="true"
        :appointment="appointment"
      />
    </template>

    <DataZeroSimple :text="$translations['data-zero'].appointments" v-else />
  </div>
</template>

<script>
import {
  selectableContainer,
  UpcomingAppointments,
  Skeleton
} from '@seliaco/red-panda'
import SpecialistsAppointmentCard from '@/components/cards/SpecialistsAppointmentCard'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'
import AppointmentRedirectMixin from '@/mixins/appointment-redirect-mixin'

export default {
  name: 'NextAppointment',
  components: {
    DataZeroSimple,
    Skeleton,
    SpecialistsAppointmentCard
  },
  mixins: [AppointmentRedirectMixin],
  directives: { selectableContainer },
  computed: {
    nextAppointmentDate () {
      const date = this.$moment(this.appointment.starts_at).startOf()

      if (date.isSame(this.$moment().tz(this.$store.getters.userTimezone), 'day')) {
        return this.$translations.time.today
      }

      return date.format('dddd, DD/MM/YYYY')
    }
  },
  data () {
    return {
      loading: true,
      appointment: null
    }
  },
  methods: {
    getNextAppointment () {
      this.loading = true

      UpcomingAppointments.getMyAppointments(
        'SPECIALIST',
        this.$store.getters['auth/specialist'].id,
        this.$store.getters.language.lang
      ).then((response) => {
        if (response.appointment) {
          this.appointment = response.appointment
          this.appointment = {
            ...this.appointment,
            service: this.appointment.specialist_service
          }
        }

        this.loading = false
      }).catch(() => {
        this.$toast({
          text: 'Ocurrió un error cargando la próxima cita',
          severity: 'error'
        })
      })
    }
  },
  mounted () {
    if (this.$store.getters['auth/specialist']) {
      this.getNextAppointment()
    } else {
      this.$store.watch(
        () => this.$store.getters['auth/specialist'],
        () => {
          this.getNextAppointment()
        }
      )
    }
  }
}
</script>

<style lang="sass" scoped>
.header
  display: flex
  justify-content: space-between
</style>
